@font-face {
	font-family: Arial,Helvetica Neue,Helvetica,sans-serif;
	src: url('../fonts/Arial.ttf') format('ttf'),
		url('../fonts/Arial.ttf') format('opentype'),
		url('../fonts/Arial.ttf') format('truetype'),
		url('../fonts/Arial\ Bold.ttf') format('ttf'),
		url('../fonts/Arial\ Bold.ttf') format('truetype'),
		url('../fonts/Arial\ Bold.ttf') format('opentype'),
		url('../fonts/Arial\ Italic.ttf') format('ttf'),
		url('../fonts/Arial\ Italic.ttf') format('truetype'),
		url('../fonts/Arial\ Italic.ttf') format('opentype'),
		url('../fonts/Arial\ Bold\ Italic.ttf') format('ttf'),
		url('../fonts/Arial\ Bold\ Italic.ttf') format('truetype'),
		url('../fonts/Arial\ Bold\ Italic.ttf') format('opentype');
	}

h1,
h2,
h3,
h4,
h5,
p,
a,
span,
blockquote,
pre {
	font-family: Arial,Helvetica Neue,Helvetica,sans-serif;
	font-style: normal;
	font-variant: normal;
}

h1 {
	font-size: 24px;
	font-weight: 700;
	line-height: 26.4px;
}

h3 {
	font-size: 14px;
	font-weight: 700;
	line-height: 15.4px;
}

p {
	font-size: 14px;
	font-weight: 400;
	line-height: 20px;
}

blockquote {
	font-size: 21px;
	font-weight: 400;
	line-height: 30px;
}

pre {
	font-size: 13px;
	font-weight: 400;
	line-height: 18.5714px;
}
