.menu-bar {
  background-color: #f8f9fa; }
  .menu-bar button {
    background-color: transparent;
    border: none !important; }
    .menu-bar button:hover {
      background-color: #f19090; }
    .menu-bar button .disabled:hover {
      background-color: #afafaf; }
    .menu-bar button.is-active {
      background-color: #f5c6c6; }
      .menu-bar button.is-active:hover {
        background-color: #f19090; }
    .menu-bar button i:hover {
      background-color: transparent !important; }

.ProseMirror {
  height: auto !important;
  min-height: 10rem !important;
  max-height: auto !important; }
  .ProseMirror .has-max-3-lines-as-default {
    min-height: 3rem !important; }
  .ProseMirror > * {
    max-width: 100% !important; }
  .ProseMirror > * + * {
    margin-top: 0.75em; }
  .ProseMirror ul,
  .ProseMirror ol {
    padding: 0 1rem; }
  .ProseMirror h1,
  .ProseMirror h2,
  .ProseMirror h3,
  .ProseMirror h4,
  .ProseMirror h5,
  .ProseMirror h6 {
    line-height: 1.1; }
  .ProseMirror code {
    background-color: rgba(97, 97, 97, 0.1);
    color: #616161; }
  .ProseMirror pre {
    background: #0D0D0D;
    color: #FFF;
    font-family: 'JetBrainsMono', monospace;
    padding: 0.75rem 1rem;
    border-radius: 0.5rem; }
    .ProseMirror pre code {
      color: inherit;
      padding: 0;
      background: none;
      font-size: 0.8rem; }
  .ProseMirror img {
    max-width: 100%;
    height: auto; }
  .ProseMirror blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(13, 13, 13, 0.1); }
  .ProseMirror hr {
    border: none;
    border-top: 2px solid rgba(13, 13, 13, 0.1);
    margin: 2rem 0; }
  .ProseMirror p {
    margin-top: .75em !important;
    margin-bottom: .75em !important; }

[contenteditable="false"] {
  padding: 0 !important; }
