.dropdown.active a.nav-link {
    /* font-weight: bold !important; */
    color: rgba(0, 0, 0, 0.9) !important;
}

#beta-button {
    color: rgba(228, 48, 48, 0.802);
}

#beta-button:hover {
    color: red;
}